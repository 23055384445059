var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "c-setting-panel-button",
        class: [_vm.$parent.isNeedFixedTools ? "c-fixedLayout" : ""],
        on: { click: _vm.toggleSettingPanel },
      },
      [_c("i", { staticClass: "el-icon-setting" })]
    ),
    _vm.showSettingPanel
      ? _c("div", {
          staticClass: "c-setting-panel",
          class: [_vm.$parent.isNeedFixedTools ? "c-fixedLayout" : ""],
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }