var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "ArchiveHistory_top" }, [
      _c("div", { staticClass: "ArchiveHistory_top_left" }, [
        _c(
          "div",
          {
            staticClass: "historyRecord",
            on: { click: _vm.archiveHistoryCallback },
          },
          [
            _c("a-button", { attrs: { type: "iconBtn", icon: "left" } }),
            _c("span", [_vm._v("历史归档")]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "ArchiveHistory_top_left_data" },
          [
            _c("span", [_vm._v("日期选择")]),
            _c("a-cascader", {
              attrs: {
                options: _vm.options,
                placeholder: "请选择日期与时间",
                size: "small",
                "allow-clear": false,
              },
              on: { change: _vm.change },
              model: {
                value: _vm.dataTime,
                callback: function ($$v) {
                  _vm.dataTime = $$v
                },
                expression: "dataTime",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _c(
            "a-tooltip",
            { attrs: { placement: "bottom" } },
            [
              _c("template", { slot: "title" }, [_c("span", [_vm._v("导出")])]),
              _c("a-button", {
                attrs: { type: "iconBtn", icon: "daochu" },
                on: { click: _vm.getFileExportOrgTree },
              }),
            ],
            2
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "ArchiveHistory_count" }, [
      _c(
        "div",
        { staticClass: "ArchiveHistory_count_left beauty-scroll" },
        [
          _c(
            "a-menu",
            {
              attrs: { mode: "inline" },
              model: {
                value: _vm.defaultSelectedKeys,
                callback: function ($$v) {
                  _vm.defaultSelectedKeys = $$v
                },
                expression: "defaultSelectedKeys",
              },
            },
            [
              _c(
                "a-menu-item",
                {
                  key: "framework",
                  on: {
                    click: function ($event) {
                      return _vm.titleClick("framework")
                    },
                  },
                },
                [
                  _c("svg", { staticClass: "iconpark-icon" }, [
                    _c("use", { attrs: { href: "#chart-graph" } }),
                  ]),
                  _c("span", [_vm._v("架构")]),
                ]
              ),
              _c(
                "a-sub-menu",
                { key: "post" },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _c("svg", { staticClass: "iconpark-icon" }, [
                      _c("use", { attrs: { href: "#people-5m13bljp" } }),
                    ]),
                    _c("span", [_vm._v("岗位")]),
                  ]),
                  _vm._l(_vm.postList, function (it) {
                    return _c(
                      "a-menu-item",
                      {
                        key: it.postTypeArchiveId,
                        on: {
                          click: function ($event) {
                            _vm.titleClick(
                              it.postTypeArchiveId + "+post",
                              it.postTypeArchiveId
                            )
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(it.postTypeName) + " ")]
                    )
                  }),
                ],
                2
              ),
              _c(
                "a-sub-menu",
                { key: "rank" },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _c("svg", { staticClass: "iconpark-icon" }, [
                      _c("use", { attrs: { href: "#level-5m13e8p6" } }),
                    ]),
                    _c("span", [_vm._v("职级")]),
                  ]),
                  _vm._l(_vm.rankList, function (it) {
                    return _c(
                      "a-menu-item",
                      {
                        key: it.jobTypeArchiveId,
                        on: {
                          click: function ($event) {
                            _vm.titleClick(
                              it.jobTypeArchiveId + "+rank",
                              it.jobTypeArchiveId
                            )
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(it.jobTypeName) + " ")]
                    )
                  }),
                ],
                2
              ),
              _c(
                "a-menu-item",
                {
                  key: "architecture",
                  on: {
                    click: function ($event) {
                      return _vm.titleClick("architecture")
                    },
                  },
                },
                [
                  _c("svg", { staticClass: "iconpark-icon" }, [
                    _c("use", { attrs: { href: "#chart-graph" } }),
                  ]),
                  _c("span", [_vm._v("架构图")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ArchiveHistory_count_right" },
        [
          _c("div", { staticClass: "total-organization" }, [
            _c("svg", { staticClass: "iconpark-icon cityName" }, [
              _c("use", { attrs: { href: "#building-2-fill" } }),
            ]),
            _c("span", { staticClass: "company" }, [
              _vm._v(" " + _vm._s(_vm.orgName) + " "),
            ]),
          ]),
          _vm.menuType === "framework"
            ? _c("TreeList", { attrs: { "tree-list": _vm.treeList } })
            : _vm._e(),
          _vm.menuType === "post"
            ? _c("PostList", {
                attrs: {
                  "post-type-statistics": _vm.PostTypeStatistics,
                  "post-id": _vm.postId,
                },
              })
            : _vm._e(),
          _vm.menuType === "rank"
            ? _c("RankList", {
                attrs: {
                  "rank-statistics": _vm.RankStatistics,
                  "rank-id": _vm.rankId,
                },
              })
            : _vm._e(),
          _vm.menuType === "architecture"
            ? _c("Diagram", {
                ref: "Diagram",
                attrs: { "data-tree": _vm.treeList },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }