var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%", height: "100%", "margin-top": "20px" } },
    [
      _c(
        "div",
        { staticStyle: { width: "100%", height: "100%" } },
        [
          _c("SeeksRelationGraph", {
            ref: "seeksRelationGraph",
            staticClass: "RelationGraph-main",
            attrs: {
              options: _vm.graphOptions,
              "on-node-click": _vm.nodeClick,
            },
            scopedSlots: _vm._u([
              {
                key: "node",
                fn: function (ref) {
                  var node = ref.node
                  return _c(
                    "div",
                    { staticStyle: { width: "100%", height: "100%" } },
                    [
                      _c(
                        "div",
                        {
                          class:
                            "node-p border-" + (node.deep < 3 ? node.deep : 3),
                          on: { click: _vm.getnode },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "node-l",
                              style: _vm.getColor(node.orgTypeName),
                            },
                            [_vm._v(" " + _vm._s(node.orgTypeName) + " ")]
                          ),
                          _c("div", { staticClass: "node-r" }, [
                            _c("div", { staticClass: "org-name" }, [
                              _vm._v(_vm._s(node.orgName)),
                            ]),
                            node.deep == 1
                              ? _c("div", { staticClass: "personnel-num" }, [
                                  _c("div", [
                                    _c("span", [_vm._v("全员")]),
                                    _c("span", [
                                      _vm._v(_vm._s(node.staffCount) + "人"),
                                    ]),
                                  ]),
                                  _c("div", [
                                    _c("span", [_vm._v("直属")]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(node.directlyStaffCount) + "人"
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _c(
                                  "div",
                                  { staticClass: "personnel-num" },
                                  [
                                    _c("CbAvater", {
                                      attrs: {
                                        "avater-name": node.leaderName,
                                        "on-job-id": node.onJobId,
                                        "default-name-length": 1,
                                        width: "60",
                                        size: "20",
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(node.staffCount) + "人"),
                                    ]),
                                  ],
                                  1
                                ),
                          ]),
                        ]
                      ),
                    ]
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }