<template>
  <div
    style="
        width: 100%;
        height: 100%;
      "
  >
    <div
      style="
        width: 100%;
        height: 100%;
      "
    >
      <div class="RelationGraph-header">
        <div class="back" @click="back">
          <svg class="iconpark-icon"><use href="#left" /></svg>
          <span>
            组织架构图
          </span>
        </div>
        <div>
          <a-button :style="{'marginRight':'12px'}" @click="magnify">
            放大
          </a-button>
          <a-button :style="{'marginRight':'12px'}" @click="shrink">
            缩小
          </a-button>
          <a-button :style="{'marginRight':'12px'}" @click="refresh">
            重置比例
          </a-button>
          <a-button icon="poweroff" @click="downloadImage">
            导出
          </a-button>
        </div>
      </div>
      <div class="orgChart">
        <vo-basic
          ref="orgChart"
          :data="chartData"
          :pan="pan"
          :toggle-siblings-resp="true"
          :create-node="createNode"
        />
      </div>
    </div>
  </div>
</template>
<script>
import html2canvas from 'html2canvas'
import { upload, getSignature } from '@/services/upload/index.js'
import { VoBasic } from 'vue-orgchart'
export default {
  components: { VoBasic },
  props: {
    chartData: {
      type: Object,
      default: () => {}
    },
    pan: {
      type: Boolean,
      default: true
    },
    zoom: {
      type: Boolean,
      default: true
    },
    companyColor: {
      type: String,
      default: '#23CFC6'
    },
    textColor: {
      type: String,
      default: '#FFFFFF'
    },
    contentColor: {
      type: String,
      default: '#23CFC6'
    }
  },
  data() {
    return {
      dataURL: '',
      basePath: process.env.VUE_APP_API_BASE_URL
    }
  },
  created() {
    this.changeData(this.chartData)
  },
  methods: {
    // 缩小
    shrink() {
      const dom = document.getElementsByClassName('orgchart')[0]
      dom.style.transform = dom.style.transform ? dom.style.transform : 'scale(1,1)'
      const scale = dom.style.transform.match(/(\(\S*)\,/)[1].split('(')[1]
      if (scale > 0.6) {
        dom.style.transform = `scale( ${parseFloat(scale) - 0.1},${parseFloat(scale) - 0.1} )`
      }
    },
    // 放大
    magnify() {
      const dom = document.getElementsByClassName('orgchart')[0]
      dom.style.transform = dom.style.transform ? dom.style.transform : 'scale(1,1)'
      const scale = dom.style.transform.match(/(\(\S*)\,/)[1].split('(')[1]
      if (scale < 2) {
        dom.style.transform = `scale( ${parseFloat(scale) + 0.1},${parseFloat(scale) + 0.1} )`
      }
    },
    refresh() {
      const dom = document.getElementsByClassName('orgchart')[0]
      dom.style.transform = `scale( 1,1 )`
    },
    toImage() {
      const dom = document.getElementsByClassName('orgchart')[0]
      const table = dom.getElementsByTagName('table')[0]
      dom.style.transform = `scale( 1,1 )`
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          html2canvas(table, {
            useCORS: true,
            allowTaint: true,
            x: -10,
            y: -10,
            scale: 1,
            width: table.clientWidth + 20,
            height: table.clientHeight + 20
          }).then(canvas => {
            const dataURL = canvas.toDataURL('image/png')
            this.dataURL = dataURL
            var file = this.dataURLtoBlob(dataURL, '组织架构图.png')
            console.log(file)
            this.customRequest(file)
            // resolve(dataURL)
          })
        })
      })
    },
    dataURLtoBlob(dataurl, name) { // base64转file
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], name, {
        type: mime
      })
    },
    async customRequest(file) {
      const form = new FormData()
      form.append('file', file)
      form.append('fileName', file.name)
      form.append('fileType', 'other')
      const res = await upload(form)
      if (res.code === 100) {
        const signatureRes = await getSignature({
          id: res.data.fileId
        })
        if (signatureRes.code === 100) {
          window.location.href = `${this.basePath}/hrsaas-storage/file?id=${res.data.fileId}&signature=${signatureRes.data.signature}&expireTime=${signatureRes.data.time}`
        }
      }
    },
    back() {
      this.$emit('back')
    },
    changeData(chartData) {
      for (const key in chartData) {
        if (key === 'children' && chartData[key].length > 0) {
          chartData[key].forEach(item => {
            if (item.children.length > 0) {
              this.changeData(item)
            } else {
              item.end = true
            }
          })
        }
      }
    },
    downloadImage() {
      this.toImage()
    },
    createNode(div, data) {
      console.log(data)
      if (data.orgLevel === 1) {
        if (data.leaderName) {
          div.style.width = '300px '
          div.innerHTML = `
            <div class="name" style="width: 60%; margin-left: 20%;background:${this.companyColor};color:${this.textColor}"> <p title="${data.orgName}">${data.orgName}</p><p>(${data.staffCount}人)</p></div>
            <div class="wrapper"><div class="line"></div><div class="title leader" title="负责人: ${data.leaderName}">负责人: ${data.leaderName}</div></div>
          `
        } else {
          div.style.width = 'auto'
          div.innerHTML = `
            <div class="name" style="min-width:120px;width:auto;margin:0;background:${this.companyColor};color:${this.textColor}"> ${data.orgName}</div>
          `
        }
      } else {
        if (data.leaderName) {
          if (data.end) {
            div.style.width = '300px '
            div.innerHTML = `
            <div class="name" style="width: 60%; margin-left: 20%;background:${this.contentColor};color:${this.textColor}"> <p title="${data.orgName}">${data.orgName}</p><p>(${data.staffCount}人)</p></div>
            <div class="wrapper"><div class="line"></div><div class="round"><div class="Inner"></div></div><div class="title leader" title="负责人: ${data.leaderName}">负责人: ${data.leaderName}</div></div>
          `
          } else {
            div.style.width = '300px '
            div.innerHTML = `
            <div class="name" style="width: 60%; margin-left: 20%;background:${this.contentColor};color:${this.textColor}"> <p title="${data.orgName}">${data.orgName}</p><p>(${data.staffCount}人)</p></div>
            <div class="wrapper"><div class="line"></div><div class="title leader" title="负责人: ${data.leaderName}">负责人: ${data.leaderName}</div></div>
          `
          }
        } else {
          div.style.maxWidth = '300px'
          div.innerHTML = `
            <div class="name" style="min-width:120px;background:${this.contentColor};color:${this.textColor}"> <p title="${data.orgName}">${data.orgName}</p><p>(${data.staffCount}人)</p></div>
          `
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.RelationGraph-header {
  height: 66px;
  background: #ffffff;
  padding:0 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: @sc-grey-100;
}
.orgChart{
  width: 100%;
  height: calc( 100% - 66px);
  background-color: @sc-greyBg-10;
}
/deep/.orgchart{
  background-image:
  linear-gradient(90deg,
  rgba(200, 0, 0, 0.15) 10%,
  rgba(0, 0, 0, 0) 10%),
  linear-gradient(rgba(200, 0, 0, 0.15) 10%,
  rgba(0, 0, 0, 0) 10%);
}
/deep/.vo-basic{
  height: 100% !important;
}
/deep/.title{
    width: auto!important;
    height: 30px !important;
    font-size: 12px !important;
    border-radius: 6px 6px 0 0!important;
    background: @sc-greyBg-20 !important;
    color: @sc-grey-100 !important;
    line-height: 30px !important;
    font-weight: 600 !important;
    padding: 0 8px !important;
}
/deep/.content{
  width: 100% !important;
  height: 30px !important;
  border: 0 !important;
  display: flex !important;
  justify-content: space-evenly !important;
  white-space: pre-line !important;
  div{
    display: flex;
    align-items: center;
    width: 40%;
  }
  p{
    line-height: 30px;
  }
  .img{
    width: 28px;
    height: 28px;
    border-radius:50% ;
    margin-right: 6px;
  }
  .imgcolor{
    width: 28px;
    height: 28px;
    border-radius:50%;
    margin-right: 6px;
    background-color: @sc-primary-100;
  }
}

.back {
  cursor: pointer;
  display: flex;
  align-items: center;
  .iconpark-icon{
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
}
/deep/.name{
  padding: 5px 10px !important;
  height: 30px !important;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  p:nth-child(1){
    width: 60%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 !important;
  }
  p:nth-child(2){
    width: 40%;
  }
  &:hover{
    box-shadow: 0px 0px 5px 5px @sc-greyBg-20;
  }
}
/deep/.orgchart .node{
  min-width: 120px !important;
  margin: 0 10px !important;
  padding: 0;
  border: 0;
  &:hover{
    background: transparent !important;
  }
}
/deep/.focused{
  background: transparent !important;
}
/deep/.wrapper{
  position: relative;
  text-align: left;
  padding: 20px 0 20px 30px;
    margin-left: calc(50% - 1px);
    border-left: 2px solid #000;
}
/deep/.line{
  position: absolute;
  top: 50%;
  left: 0;
  display: inline-block;
  width: 30px;
  border-top: 2px solid #000;
}
/deep/.round{
  position: absolute;
  bottom: -10px;
  left: -12px;
  width: 20px;
  height: 20px;
  border-radius:50% ;
  display: flex;
  justify-content: center;
  align-items: center;
  background: @sc-greyBg-20;
}
/deep/.Inner{
  width: 14px;
  height: 14px;
  border-radius:50% ;
  background: #ffffff;
}
</style>
