var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "miniView", staticClass: "c-mini-graph" }, [
    _c(
      "div",
      {
        staticClass: "c-mini-canvas",
        style: {
          width: 100 * _vm.zoom + "px",
          height:
            ((_vm.graphSetting.canvasNVInfo.height * 100) /
              _vm.graphSetting.canvasNVInfo.width) *
              _vm.zoom +
            "px",
        },
      },
      [
        _vm._l(_vm.$parent.nodeViewList, function (thisNode) {
          return [
            _vm.isAllowShowNode(thisNode)
              ? _c("div", {
                  key: thisNode.id,
                  staticClass: "c-mini-node",
                  style: {
                    "margin-left":
                      ((thisNode.x * 100) / _vm.graphSetting.canvasSize.width) *
                        _vm.zoom +
                      "px",
                    "margin-top":
                      ((thisNode.y * 100) / _vm.graphSetting.canvasSize.width) *
                        _vm.zoom +
                      "px",
                  },
                })
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
    _c("div", { staticClass: "c-mini-view", style: _vm.getPositionData() }, [
      _c("i", { staticClass: "el-icon-view" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }