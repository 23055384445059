var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "AddFrameworkModal" },
    [
      _c(
        "Modal",
        {
          attrs: {
            closable: true,
            title: _vm.mode == "edit" ? "编辑组织" : "添加组织",
            visible: _vm.visible,
            size: "normal",
            "mask-closable": false,
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "form",
                attrs: {
                  rules: _vm.rules,
                  layout: _vm.form.layout,
                  model: _vm.form,
                },
              },
              "a-form-model",
              {
                labelCol: { span: 5 },
                wrapperCol: { span: 19 },
              },
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "组织名称", prop: "orgName" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入组织名称" },
                    model: {
                      value: _vm.form.orgName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "orgName", $$v)
                      },
                      expression: "form.orgName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  staticClass: "orgin-type",
                  attrs: { label: "组织类型", prop: "orgTypeName" },
                },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        placeholder: "请选择组织类型",
                        "default-value": _vm.form.orgTypeName,
                      },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.form.orgTypeName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "orgTypeName", $$v)
                        },
                        expression: "form.orgTypeName",
                      },
                    },
                    _vm._l(_vm.orginOption, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: item.orgTypeId,
                          attrs: { value: item.orgTypeId },
                        },
                        [_vm._v(" " + _vm._s(item.orgTypeName) + " ")]
                      )
                    }),
                    1
                  ),
                  _c(
                    "a-checkbox",
                    {
                      attrs: {
                        disabled: !!_vm.editItemId,
                        "default-checked": _vm.form.invented,
                        value: "1",
                        name: "type",
                      },
                      on: { change: _vm.inventedChange },
                      model: {
                        value: _vm.form.invented,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "invented", $$v)
                        },
                        expression: "form.invented",
                      },
                    },
                    [_vm._v(" 虚拟组织 ")]
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "组织说明", prop: "detail" } },
                [
                  _c("a-textarea", {
                    attrs: {
                      "auto-size": { minRows: 4, maxRows: 5 },
                      placeholder: "请输入组织说明",
                    },
                    model: {
                      value: _vm.form.detail,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "detail", $$v)
                      },
                      expression: "form.detail",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }