var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "seeksRelationGraph",
      staticStyle: { "box-sizing": "border-box" },
      style: { width: "100%", height: "100%" },
      on: {
        resize: function ($event) {
          return _vm.refreshNVAnalysisInfo()
        },
      },
    },
    [
      _vm.graphSetting.allowShowSettingPanel
        ? _c("GraphSettingPanel", {
            attrs: { "graph-setting": _vm.graphSetting },
            scopedSlots: _vm._u(
              [
                {
                  key: "settingPanelPlus",
                  fn: function (ref) {
                    var setting = ref.setting
                    return _c(
                      "div",
                      {},
                      [_vm._t("settingPanelPlus", null, { setting: setting })],
                      2
                    )
                  },
                },
              ],
              null,
              true
            ),
          })
        : _vm._e(),
      _c("graph-mini-name-filter", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.graphSetting.allowShowMiniNameFilter === true,
            expression: "graphSetting.allowShowMiniNameFilter===true",
          },
        ],
        attrs: { "graph-setting": _vm.graphSetting },
      }),
      _c("graph-mini-tool-bar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.graphSetting.allowShowMiniToolBar === true,
            expression: "graphSetting.allowShowMiniToolBar===true",
          },
        ],
        attrs: { "graph-setting": _vm.graphSetting },
      }),
      _vm.graphSetting.allowShowMiniView === true
        ? _c("graph-mini-view", {
            attrs: { "graph-setting": _vm.graphSetting },
          })
        : _vm._e(),
      _vm._t("graphPlug", null, { graph: this }),
      _c(
        "div",
        {
          staticClass: "rel-map",
          class: [
            _vm.graphSetting.layoutClassName,
            _vm.graphSetting.backgrounImageNoRepeat
              ? "rel-map-background-norepeat"
              : "",
          ],
          style: {
            width: "100%",
            height: "100%",
            "background-image": "url(" + _vm.graphSetting.backgrounImage + ")",
          },
          on: {
            mousedown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "left", 37, $event.key, [
                  "Left",
                  "ArrowLeft",
                ])
              ) {
                return null
              }
              if ("button" in $event && $event.button !== 0) {
                return null
              }
              $event.stopPropagation()
              return _vm.onDragStart($event)
            },
            mousewheel: _vm.mouseListener,
          },
        },
        [
          _c(
            "div",
            {
              ref: "seeksRGCanvas",
              staticClass: "rel-map-canvas",
              style: _vm.canvasSizeAndPosition,
            },
            [
              _c(
                "div",
                { staticClass: "rel-nodediv rel-nodediv-for-webkit" },
                _vm._l(_vm.nodeViewList, function (thisNode) {
                  return _c("SeeksRGNode", {
                    key: thisNode.seeks_id,
                    attrs: {
                      "node-props": thisNode,
                      "on-node-click": _vm.onRGNodeClick,
                      "graph-setting": _vm.graphSetting,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "node",
                          fn: function (ref) {
                            var node = ref.node
                            return [_vm._t("node", null, { node: node })]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
              _c(
                "div",
                {
                  ref: "rgCanvas",
                  staticClass: "rel-linediv",
                  staticStyle: { overflow: "visible" },
                },
                [
                  _c(
                    "svg",
                    {
                      staticStyle: { overflow: "visible" },
                      style: {
                        width: _vm.graphSetting.canvasSize.width + "px",
                        height: _vm.graphSetting.canvasSize.height + "px",
                      },
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        version: "1.1",
                      },
                    },
                    [
                      _c(
                        "defs",
                        [
                          _c(
                            "linearGradient",
                            {
                              attrs: {
                                id: _vm.graphSetting.instanceId + "-lineStyle",
                                x1: "1",
                                y1: "0",
                                x2: "0",
                                y2: "0",
                              },
                            },
                            [
                              _c("stop", {
                                attrs: {
                                  offset: "0%",
                                  "stop-color": "#e52c5c",
                                },
                              }),
                              _c("stop", {
                                attrs: {
                                  offset: "100%",
                                  "stop-color": "#FD8B37",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "marker",
                            {
                              attrs: {
                                id:
                                  _vm.graphSetting.instanceId +
                                  "-arrow-default",
                                markerWidth:
                                  _vm.graphSetting.defaultLineMarker
                                    .markerWidth,
                                markerHeight:
                                  _vm.graphSetting.defaultLineMarker
                                    .markerHeight,
                                refX: _vm.graphSetting.defaultLineMarker.refX,
                                refY: _vm.graphSetting.defaultLineMarker.refY,
                                "marker-units": "strokeWidth",
                                orient: "auto",
                                viewBox: "0 0 12 12",
                              },
                            },
                            [
                              _c("path", {
                                style: {
                                  fill: _vm.graphSetting.defaultLineColor,
                                },
                                attrs: {
                                  d: _vm.graphSetting.defaultLineMarker.data,
                                },
                              }),
                            ]
                          ),
                          _c(
                            "marker",
                            {
                              attrs: {
                                id:
                                  _vm.graphSetting.instanceId +
                                  "-arrow-checked",
                                markerUnits: "strokeWidth",
                                markerWidth: "12",
                                markerHeight: "12",
                                viewBox: "0 0 12 12",
                                refX: "6",
                                refY: "6",
                                orient: "auto",
                              },
                            },
                            [
                              _c("path", {
                                style: { fill: "#FD8B37" },
                                attrs: { d: "M2,2 L10,6 L2,10 L6,6 L2,2" },
                              }),
                            ]
                          ),
                          _vm._l(_vm.allLineColors, function (thisColor) {
                            return _c(
                              "marker",
                              {
                                key: thisColor.id,
                                attrs: {
                                  id:
                                    _vm.graphSetting.instanceId +
                                    "-arrow-" +
                                    thisColor.id,
                                  markerWidth:
                                    _vm.graphSetting.defaultLineMarker
                                      .markerWidth,
                                  markerHeight:
                                    _vm.graphSetting.defaultLineMarker
                                      .markerHeight,
                                  refX: _vm.graphSetting.defaultLineMarker.refX,
                                  refY: _vm.graphSetting.defaultLineMarker.refY,
                                  "marker-units": "strokeWidth",
                                  orient: "auto",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    fill:
                                      _vm.graphSetting.defaultLineMarker
                                        .color || thisColor.color,
                                    d: _vm.graphSetting.defaultLineMarker.data,
                                  },
                                }),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _vm._l(_vm.lineViewList, function (thisLine) {
                        return _c("SeeksRGLink", {
                          key: thisLine.seeks_id,
                          attrs: {
                            "line-props": thisLine,
                            "graph-setting": _vm.graphSetting,
                            "on-line-click": _vm.onRGLineClick,
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm.$scopedSlots.bottomPanel
        ? _c(
            "graph-bottom-panel",
            { attrs: { "graph-setting": _vm.graphSetting } },
            [
              _c(
                "template",
                { slot: "bottomPanel" },
                [_vm._t("bottomPanel", null, { graph: this })],
                2
              ),
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticStyle: { clear: "both", height: "1px" } }),
      _vm.isShowZoomCenter
        ? _c(
            "div",
            {
              staticStyle: {
                position: "fixed",
                top: "0px",
                right: "0px",
                "font-size": "12px",
                "background-color": "#333333",
                color: "#ffffff",
                "z-index": "9999",
                padding: "20px",
              },
              style: {
                left: _vm.debugPanelPosition ? "" : "0px",
                right: _vm.debugPanelPosition ? "0px" : "",
              },
              on: { click: _vm.moveDebugPanel },
            },
            [
              _c("div", [
                _c("pre", [
                  _vm._v(
                    _vm._s(
                      JSON.stringify(_vm.graphSetting.canvasOffset, null, 2)
                    )
                  ),
                ]),
              ]),
              _c("div", [
                _c("pre", [
                  _vm._v(
                    _vm._s(JSON.stringify(_vm.graphSetting.viewNVInfo, null, 2))
                  ),
                ]),
              ]),
              _c("div", [
                _c("pre", [
                  _vm._v(
                    _vm._s(
                      JSON.stringify(_vm.graphSetting.canvasNVInfo, null, 2)
                    )
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.isShowZoomCenter
        ? _c("div", {
            staticStyle: {
              position: "fixed",
              top: "0px",
              width: "2px",
              height: "2000px",
              "z-index": "99999",
              "border-left": "blue dotted 2px",
              opacity: "0.6",
            },
            style: {
              left:
                _vm.graphSetting.canvasNVInfo.x +
                _vm.graphSetting.viewNVInfo.x +
                "px",
              top: _vm.graphSetting.viewNVInfo.y + "px",
            },
          })
        : _vm._e(),
      _vm.isShowZoomCenter
        ? _c("div", {
            staticStyle: {
              position: "fixed",
              left: "0px",
              height: "2px",
              width: "2000px",
              "z-index": "99999",
              "border-top": "blue dotted 2px",
              opacity: "0.6",
            },
            style: {
              top:
                _vm.graphSetting.canvasNVInfo.y +
                _vm.graphSetting.viewNVInfo.y +
                "px",
              left: _vm.graphSetting.viewNVInfo.x + "px",
            },
          })
        : _vm._e(),
      _vm.isShowZoomCenter
        ? _c("div", {
            staticStyle: {
              position: "fixed",
              top: "0px",
              width: "4px",
              height: "2000px",
              "z-index": "99999",
              "border-left": "green dotted 2px",
              opacity: "0.4",
            },
            style: {
              left:
                _vm.graphSetting.canvasNVInfo.x +
                _vm.graphSetting.canvasNVInfo.width / 2 +
                _vm.graphSetting.viewNVInfo.x +
                "px",
              top: _vm.graphSetting.viewNVInfo.y + "px",
            },
          })
        : _vm._e(),
      _vm.isShowZoomCenter
        ? _c("div", {
            staticStyle: {
              position: "fixed",
              left: "0px",
              height: "4px",
              width: "2000px",
              "z-index": "99999",
              "border-top": "green dotted 2px",
              opacity: "0.4",
            },
            style: {
              top:
                _vm.graphSetting.canvasNVInfo.y +
                _vm.graphSetting.canvasNVInfo.height / 2 +
                _vm.graphSetting.viewNVInfo.y +
                "px",
              left: _vm.graphSetting.viewNVInfo.x + "px",
            },
          })
        : _vm._e(),
      _vm.isShowZoomCenter
        ? _c("div", {
            staticStyle: {
              position: "fixed",
              top: "0px",
              width: "2px",
              height: "2000px",
              "z-index": "99999",
              "border-left": "blue dotted 2px",
              opacity: "0.6",
            },
            style: {
              left:
                _vm.graphSetting.canvasNVInfo.x +
                _vm.graphSetting.canvasNVInfo.width +
                _vm.graphSetting.viewNVInfo.x +
                "px",
              top: _vm.graphSetting.viewNVInfo.y + "px",
            },
          })
        : _vm._e(),
      _vm.isShowZoomCenter
        ? _c("div", {
            staticStyle: {
              position: "fixed",
              left: "0px",
              height: "2px",
              width: "2000px",
              "z-index": "99999",
              "border-top": "blue dotted 2px",
              opacity: "0.6",
            },
            style: {
              top:
                _vm.graphSetting.canvasNVInfo.y +
                _vm.graphSetting.canvasNVInfo.height +
                _vm.graphSetting.viewNVInfo.y +
                "px",
              left: _vm.graphSetting.viewNVInfo.x + "px",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }