var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-rg-bottom-panel",
      style: {
        "margin-topd": _vm.graphSetting.viewSize.height - _vm.height + "px",
        height: _vm.height + "px",
        "margin-top": -_vm.height + "px",
      },
    },
    [_vm._t("bottomPanel")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }