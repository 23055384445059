var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c("div", { staticClass: "top" }, [
      _c("span", [
        _vm._v(
          "在职总数：" + _vm._s(_vm.rankStatisticsValue.incumbencyCount) + "人"
        ),
      ]),
      _c("span", { staticClass: "num" }, [
        _vm._v("定级人数：" + _vm._s(_vm.rankStatisticsValue.gradedCount)),
      ]),
    ]),
    _c(
      "div",
      [
        _c(
          "custom-table",
          {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.dataSource,
              "row-key": "jobGradeArchiveId",
              pagination: false,
            },
          },
          [
            _c(
              "span",
              { attrs: { slot: "proportionTitle" }, slot: "proportionTitle" },
              [
                _vm._v(" 占比 "),
                _c(
                  "a-tooltip",
                  { attrs: { placement: "top" } },
                  [
                    _c("template", { slot: "title" }, [
                      _c("span", [
                        _vm._v("当前职级人数占当前职类总人数的比例"),
                      ]),
                    ]),
                    _c("span", { staticClass: "details" }, [
                      _c("svg", { staticClass: "iconpark-icon" }, [
                        _c("use", { attrs: { href: "#help" } }),
                      ]),
                      _vm._v(" (%) "),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }