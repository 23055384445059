var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "MovementModal" },
    [
      _c(
        "Modal",
        {
          attrs: {
            closable: true,
            title: "移动",
            visible: _vm.visible,
            size: "normal",
            "mask-closable": false,
          },
          on: {
            ok: _vm.handleOk,
            cancel: function () {
              _vm.visible = false
            },
          },
        },
        [
          _c("a-tree-select", {
            staticStyle: { width: "100%" },
            attrs: {
              "dropdown-style": { maxHeight: "400px", overflow: "auto" },
              "tree-data": _vm.treeData,
              placeholder: "请选择上级组织",
              "tree-default-expand-all": "",
              "replace-fields": {
                children: "children",
                title: "orgName",
                key: "orgId",
                value: "orgId",
              },
            },
            on: { select: _vm.treeChange },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }