var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "framework" },
    [
      _c("div", { staticClass: "organization-tree-blcok" }, [
        _c("div", { staticClass: "organization-btn" }, [
          _c(
            "div",
            { staticClass: "operation" },
            [
              _c(
                "a-button",
                {
                  staticClass: "frame-btn",
                  attrs: { type: "primary", prefix: "chart-graph" },
                  on: {
                    click: function ($event) {
                      _vm.frameworkVisible = true
                    },
                  },
                },
                [_c("span", { staticClass: "chartGraph" }, [_vm._v("框架图")])]
              ),
              _vm.env === "development"
                ? _c(
                    "a-button",
                    {
                      staticClass: "frame-btn",
                      on: {
                        click: function ($event) {
                          _vm.frameVisible = true
                        },
                      },
                    },
                    [
                      _c("svg", { staticClass: "iconpark-icon" }, [
                        _c("use", { attrs: { href: "#chart-graph" } }),
                      ]),
                      _c("span", { staticClass: "chartGraph" }, [
                        _vm._v("框架图"),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "virtual_organization" },
                [
                  _c("a-switch", {
                    attrs: { size: "small" },
                    on: { change: _vm.virtualSwitchChange },
                    model: {
                      value: _vm.virtualSwitch,
                      callback: function ($$v) {
                        _vm.virtualSwitch = $$v
                      },
                      expression: "virtualSwitch",
                    },
                  }),
                  _c("span", [_vm._v("显示虚拟组织")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "operation-right" },
            [
              _c(
                "a-dropdown",
                {
                  staticClass: "more-operations",
                  attrs: { type: "primary" },
                  scopedSlots: _vm._u([
                    {
                      key: "overlay",
                      fn: function () {
                        return [
                          _c(
                            "a-menu",
                            [
                              _c(
                                "a-menu-item",
                                { on: { click: _vm.getFile } },
                                [_vm._v("归档")]
                              ),
                              _c(
                                "a-menu-item",
                                { on: { click: _vm.getArchiveHistory } },
                                [_vm._v("归档记录")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    { staticClass: "top-operation_operation" },
                    [
                      _c("a-button", { attrs: { suffix: "left" } }, [
                        _vm._v("归档"),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "a-dropdown",
                {
                  staticClass: "more-operations",
                  attrs: { type: "primary" },
                  scopedSlots: _vm._u([
                    {
                      key: "overlay",
                      fn: function () {
                        return [
                          _c(
                            "a-menu",
                            [
                              _c(
                                "a-menu-item",
                                { on: { click: _vm.getMerge } },
                                [_vm._v("合并")]
                              ),
                              _c(
                                "a-menu-item",
                                {
                                  on: { click: _vm.getOrganizationManagement },
                                },
                                [_vm._v("组织类型管理")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "div",
                    { staticClass: "top-operation_operation" },
                    [
                      _c("a-button", { attrs: { suffix: "left" } }, [
                        _vm._v("更多"),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "exportBtn" },
                [
                  _c(
                    "a-tooltip",
                    { attrs: { placement: "top" } },
                    [
                      _c("template", { slot: "title" }, [
                        _c("span", [_vm._v("导出")]),
                      ]),
                      _c("a-button", {
                        attrs: { type: "iconBtn", icon: "daochu" },
                        on: { click: _vm.exportTreeList },
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "top-operation" }, [
          _c("div", { staticClass: "total-organization-left" }, [
            _c("svg", { staticClass: "iconpark-icon cityName" }, [
              _c("use", { attrs: { href: "#building-2-fill" } }),
            ]),
            _c("span", { staticClass: "company" }, [
              _vm._v(" " + _vm._s(_vm.companyinfo.orgName) + " "),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "total-organization-right" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "link" },
                  on: {
                    click: function ($event) {
                      return _vm.addLowerLevel(_vm.companyinfo.orgId)
                    },
                  },
                },
                [_vm._v(" 新增下级 ")]
              ),
              _c(
                "a-button",
                {
                  staticClass: "add-first-item",
                  attrs: { type: "link" },
                  on: { click: _vm.orgImport },
                },
                [_vm._v(" 组织部门导入 ")]
              ),
            ],
            1
          ),
        ]),
        _vm.gData.length > 0
          ? _c(
              "div",
              { staticClass: "tree-block" },
              [
                _c("a-tree", {
                  ref: "Tree",
                  attrs: {
                    "expanded-keys": _vm.expandedKeys,
                    "auto-expand-parent": _vm.autoExpandParent,
                    "tree-data": _vm.gData,
                    "replace-fields": {
                      children: "children",
                      title: "orgName",
                      key: "orgId",
                    },
                    "show-line": false,
                    draggable: true,
                    selectable: false,
                    "block-node": false,
                  },
                  on: { expand: _vm.onExpand, drop: _vm.onDrop },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "name",
                        fn: function (ref) {
                          var orgName = ref.orgName
                          var leaderName = ref.leaderName
                          var leaderType = ref.leaderType
                          var orgTypeName = ref.orgTypeName
                          var orgId = ref.orgId
                          var invented = ref.invented
                          var children = ref.children
                          var onJobId = ref.onJobId
                          return [
                            _c("div", { staticClass: "name" }, [
                              _c("span", { attrs: { title: orgName } }, [
                                _vm._v(" " + _vm._s(orgName) + " "),
                              ]),
                            ]),
                            _c("div", { staticClass: "last-line" }, [
                              _c("div", { staticClass: "leader-name" }, [
                                leaderName
                                  ? _c(
                                      "div",
                                      { staticClass: "leader-header-img" },
                                      [
                                        _c("CbAvater", {
                                          attrs: {
                                            "on-job-id": onJobId,
                                            "avater-name": leaderName,
                                            size: "30",
                                            "default-name-length": 2,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "orgin-name" }, [
                                _vm._v(" " + _vm._s(orgTypeName) + " "),
                              ]),
                              invented
                                ? _c("div", { staticClass: "fictitious" }, [
                                    _vm._v(" 虚拟 "),
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "add-lower-level" },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      staticClass: "addNext",
                                      attrs: { type: "link" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addLowerLevel(
                                            orgId,
                                            invented
                                          )
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("新增下级")])]
                                  ),
                                  _c(
                                    "a-button",
                                    {
                                      staticClass: "organization",
                                      attrs: { type: "link" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeOrganization(
                                            orgId,
                                            onJobId,
                                            leaderType
                                          )
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("组织负责人")])]
                                  ),
                                  _c(
                                    "a-popover",
                                    {
                                      attrs: {
                                        placement: "bottomRight",
                                        trigger: "hover",
                                        "destroy-tooltip-on-hide": "",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "content" }, [
                                        _c(
                                          "div",
                                          { staticClass: "more-handle-btn" },
                                          [
                                            _c(
                                              "a-button",
                                              {
                                                attrs: { type: "link" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editItem(orgId)
                                                  },
                                                },
                                              },
                                              [_c("span", [_vm._v("编辑")])]
                                            ),
                                            _c(
                                              "a-button",
                                              {
                                                attrs: { type: "link" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.detailsItem(
                                                      orgId
                                                    )
                                                  },
                                                },
                                              },
                                              [_c("span", [_vm._v("详情")])]
                                            ),
                                            _c(
                                              "a-button",
                                              {
                                                attrs: { type: "link" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.moveItem(orgId)
                                                  },
                                                },
                                              },
                                              [_c("span", [_vm._v("移动")])]
                                            ),
                                            !invented ||
                                            (invented && children.length <= 0)
                                              ? _c(
                                                  "a-button",
                                                  {
                                                    staticClass: "delete",
                                                    attrs: { type: "link" },
                                                    on: {
                                                      "~click": function (
                                                        $event
                                                      ) {
                                                        return _vm.deleteItem(
                                                          orgId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_c("span", [_vm._v("删除")])]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "more-handle" },
                                        [_vm._v(" ··· ")]
                                      ),
                                    ],
                                    2
                                  ),
                                  _c("div", { staticClass: "drag_class" }, [
                                    _c(
                                      "svg",
                                      { staticClass: "iconpark-icon dragIcon" },
                                      [_c("use", { attrs: { href: "#drag" } })]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3186362238
                  ),
                }),
              ],
              1
            )
          : _c("div", { staticClass: "empty_box" }, [
              _c("img", {
                staticClass: "emptyImg",
                attrs: { src: _vm.emptyImg, alt: "" },
              }),
              _c("p", [_vm._v("暂无数据")]),
            ]),
      ]),
      _vm.showImport
        ? _c(
            "div",
            { staticClass: "archive_history" },
            [_c("ImportOrg", { on: { importCallback: _vm.importCallback } })],
            1
          )
        : _vm._e(),
      _c("AddFrameworkModal", {
        ref: "AddFrameworkModal",
        on: { AddFrameworkModalOk: _vm.AddFrameworkModalOk },
      }),
      _c("StaffDetailsDrawer", { ref: "StaffDetailsDrawer" }),
      _vm.frameworkVisible
        ? _c(
            "div",
            { staticClass: "StructureChartBlock" },
            [
              _c("CbStructureChart", {
                attrs: { "data-tree": _vm.fulOrgTree },
                on: {
                  back: function ($event) {
                    _vm.frameworkVisible = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.frameVisible
        ? _c(
            "div",
            { staticClass: "StructureChartBlock" },
            [
              _c("orgChart", {
                attrs: { "chart-data": _vm.chartData },
                on: {
                  back: function ($event) {
                    _vm.frameVisible = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("MovementModal", {
        ref: "MovementModal",
        on: { moveSuccess: _vm.moveSuccess },
      }),
      _c("Merge", { ref: "Merge", on: { mergeCallback: _vm.getOrgTree } }),
      _c("OrganizationManagement", { ref: "OrganizationManagement" }),
      _vm.archiveHistoryVisible
        ? _c(
            "div",
            { staticClass: "archive_history" },
            [
              _c("ArchiveHistory", {
                on: {
                  archiveHistoryCallback: function ($event) {
                    _vm.archiveHistoryVisible = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("Organizationleader", {
        ref: "organizationLeader",
        on: { changeFlag: _vm.changeFlag },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }