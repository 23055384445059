var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            "overflow-auto": "",
            closable: true,
            title: "组织类型管理",
            visible: _vm.visible,
            "z-index": 2,
            "mask-closable": false,
            "ok-text": "保存",
            size: "normal",
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _c("div", { staticClass: "organization_title" }, [
            _vm._v("组织类型"),
          ]),
          _c(
            "vueDragGable",
            {
              staticClass: "wrapper",
              model: {
                value: _vm.orgTypeAllList,
                callback: function ($$v) {
                  _vm.orgTypeAllList = $$v
                },
                expression: "orgTypeAllList",
              },
            },
            [
              _c(
                "transition-group",
                _vm._l(_vm.orgTypeAllList, function (it, index) {
                  return _c(
                    "div",
                    {
                      key: "management_list" + index,
                      staticClass: "management_list",
                    },
                    [
                      _c(
                        "svg",
                        { staticClass: "iconpark-icon management_list_icon" },
                        [_c("use", { attrs: { href: "#application-two" } })]
                      ),
                      _c("a-input", {
                        staticClass: "management_list_input",
                        attrs: {
                          placeholder: "自定义选项，1-50字符",
                          disabled: it.createTypeDisabled,
                        },
                        model: {
                          value: it.orgTypeName,
                          callback: function ($$v) {
                            _vm.$set(it, "orgTypeName", $$v)
                          },
                          expression: "it.orgTypeName",
                        },
                      }),
                      _c("a-switch", {
                        attrs: { size: "small" },
                        model: {
                          value: it.switchStatusType,
                          callback: function ($$v) {
                            _vm.$set(it, "switchStatusType", $$v)
                          },
                          expression: "it.switchStatusType",
                        },
                      }),
                      it.createType === 1
                        ? _c(
                            "span",
                            {
                              staticClass: "management_list_del",
                              on: {
                                click: function ($event) {
                                  return _vm.delOrganization(it, index)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm.showAddButton
            ? _c(
                "a-button",
                {
                  staticClass: "management_list_button",
                  attrs: { type: "link", prefix: "add-one" },
                  on: { click: _vm.addOrganizationType },
                },
                [_vm._v("添加")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }