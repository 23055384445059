var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c(
      "div",
      { staticClass: "tree-block" },
      [
        _c("a-tree", {
          attrs: {
            "expanded-keys": _vm.expandedKeys,
            "auto-expand-parent": _vm.autoExpandParent,
            "tree-data": _vm.gData,
            "replace-fields": {
              children: "children",
              title: "orgName",
              key: "orgId",
            },
            "show-line": false,
            draggable: true,
            selectable: false,
            "block-node": false,
          },
          on: { expand: _vm.onExpand },
          scopedSlots: _vm._u([
            {
              key: "name",
              fn: function (ref) {
                var onJobId = ref.onJobId
                var orgName = ref.orgName
                var leaderName = ref.leaderName
                var orgTypeName = ref.orgTypeName
                var invented = ref.invented
                return [
                  _c("span", [_vm._v(" " + _vm._s(orgName) + " ")]),
                  _c("div", { staticClass: "last-line" }, [
                    _c("div", { staticClass: "leader-name" }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          leaderName
                            ? _c("CbAvater", {
                                attrs: {
                                  "avater-name": leaderName,
                                  "on-job-id": onJobId,
                                  size: "30",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "orgin-name" }, [
                      _vm._v(" " + _vm._s(orgTypeName) + " "),
                    ]),
                    invented
                      ? _c("div", { staticClass: "fictitious" }, [
                          _vm._v(" 虚拟 "),
                        ])
                      : _vm._e(),
                  ]),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }