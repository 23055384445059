var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "StaffDetailsDrawer" },
    [
      _c(
        "Drawer",
        {
          staticClass: "staff-drawer",
          attrs: {
            size: "small",
            "is-mask": "",
            "display-btn": "",
            placement: "right",
            closable: "",
            "z-index": 2,
            visible: _vm.staffVisible,
          },
          on: { close: _vm.staffVisibleClose },
        },
        [
          _c("template", { slot: "title" }, [
            _vm._v(" " + _vm._s(_vm.details.orgName) + " "),
          ]),
          !_vm.leaderName
            ? _c("div", { staticClass: "principal" }, [
                _c("div", { staticClass: "empty_div" }, [_vm._v("暂无负责人")]),
              ])
            : _c("div", { staticClass: "principal" }, [
                _c("div", { staticClass: "leader-header" }, [
                  _vm.leaderName
                    ? _c(
                        "span",
                        [
                          _c("Avater", {
                            attrs: {
                              "on-job-id": _vm.details.onJobId,
                              "avater-name": _vm.leaderName,
                              size: "34",
                              "show-user-name": false,
                              "is-person-info": "",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c("div", [_vm._v("负责人：")]),
                _c("div", [_vm._v(" " + _vm._s(_vm.leaderName))]),
              ]),
          _c("div", { staticClass: "description" }, [
            _c("span", [_vm._v("组织说明：")]),
            _vm.detail.length === 0 ? _c("span", [_vm._v("暂无")]) : _vm._e(),
            !_vm.openContentDetail
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.detail.length > 20
                        ? _vm.detail.slice(0, 20) + "..."
                        : _vm.detail
                    )
                  ),
                ])
              : _c("span", [_vm._v(_vm._s(_vm.detail))]),
            _vm.detail.length > 20
              ? _c(
                  "a",
                  {
                    staticClass: "open_content",
                    on: { click: _vm.openContent },
                  },
                  [_vm._v(_vm._s(_vm.openContentDetail ? "收起" : "展开"))]
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "addPeoButton" },
            [
              _vm.details.invented
                ? _c("a-button", { on: { click: _vm.addPeople } }, [
                    _vm._v("添加人员"),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "items" },
            [
              _c(
                "a-tabs",
                {
                  model: {
                    value: _vm.tabsKey,
                    callback: function ($$v) {
                      _vm.tabsKey = $$v
                    },
                    expression: "tabsKey",
                  },
                },
                [
                  !_vm.details.invented
                    ? _c("a-tab-pane", { key: "1", attrs: { tab: "直属" } }, [
                        _vm.directStaff.length > 0
                          ? _c(
                              "div",
                              _vm._l(_vm.directStaff, function (item, index) {
                                return _c(
                                  "ul",
                                  { key: index, staticClass: "staff-list" },
                                  [
                                    _c("li", [
                                      _c("div", { staticClass: "users-info" }, [
                                        item.staffName
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "leader_name_img",
                                              },
                                              [
                                                _c("Avater", {
                                                  attrs: {
                                                    "on-job-id": item.onJobId,
                                                    size: 34,
                                                    "avater-name":
                                                      item.staffName,
                                                    "is-person-info": "",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("span", [
                                          _vm._v(_vm._s(item.phoneNum)),
                                        ]),
                                      ]),
                                      item.staffNum
                                        ? _c(
                                            "div",
                                            { staticClass: "staff_number" },
                                            [
                                              _c(
                                                "a-tooltip",
                                                {
                                                  attrs: { placement: "left" },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "title" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.staffNum
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("span", [
                                                    _vm._v("工号："),
                                                  ]),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.staffNum)
                                                    ),
                                                  ]),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            )
                          : _c("div", { staticClass: "empty_box" }, [
                              _c("img", {
                                staticClass: "emptyImg",
                                attrs: { src: _vm.emptyImg, alt: "" },
                              }),
                              _c("p", [_vm._v("暂无数据")]),
                            ]),
                      ])
                    : _vm._e(),
                  _c("a-tab-pane", { key: "2", attrs: { tab: "全员" } }, [
                    _vm.allStaff.length > 0
                      ? _c(
                          "div",
                          _vm._l(_vm.allStaff, function (item, index) {
                            return _c(
                              "ul",
                              { key: index, staticClass: "staff-list" },
                              [
                                _c("li", [
                                  _c("div", { staticClass: "users-info" }, [
                                    item.staffName
                                      ? _c(
                                          "span",
                                          { staticClass: "leader_name_img" },
                                          [
                                            _c("Avater", {
                                              attrs: {
                                                "on-job-id": item.onJobId,
                                                size: 34,
                                                "avater-name": item.staffName,
                                                "is-person-info": "",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("span", [_vm._v(_vm._s(item.phoneNum))]),
                                  ]),
                                  item.staffNum
                                    ? _c(
                                        "div",
                                        { staticClass: "staff_number" },
                                        [
                                          _c(
                                            "a-tooltip",
                                            { attrs: { placement: "left" } },
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.staffNum) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("span", [_vm._v("工号：")]),
                                              _c("span", [
                                                _vm._v(_vm._s(item.staffNum)),
                                              ]),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.details.invented
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "a-popconfirm",
                                            {
                                              attrs: {
                                                title: "是否确认删除？",
                                                placement: "left",
                                                "ok-text": "确认",
                                                "cancel-text": "取消",
                                              },
                                              on: {
                                                confirm: function ($event) {
                                                  return _vm.delFictitious(
                                                    item.onJobId
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "iconpark-icon delicon",
                                                },
                                                [
                                                  _c("use", {
                                                    attrs: {
                                                      href: "#close-small",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          }),
                          0
                        )
                      : _c("div", { staticClass: "empty_box" }, [
                          _c("img", {
                            staticClass: "emptyImg",
                            attrs: { src: _vm.emptyImg, alt: "" },
                          }),
                          _c("p", [_vm._v("暂无数据")]),
                        ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("PerModelSelect", {
            ref: "PerModelSelect",
            on: { perModelConfirm: _vm.perModelConfirm },
            model: {
              value: _vm.visiblePeople,
              callback: function ($$v) {
                _vm.visiblePeople = $$v
              },
              expression: "visiblePeople",
            },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }