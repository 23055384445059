var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "c-mini-namefilter",
    style: { "margin-left": _vm.graphSetting.viewELSize.width - 350 + "px" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }