var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lineProps.isHide !== true &&
    _vm.isAllowShowNode(_vm.lineProps.fromNode) &&
    _vm.isAllowShowNode(_vm.lineProps.toNode)
    ? _c(
        "g",
        { ref: "seeksRGLink", attrs: { transform: "translate(0,0)" } },
        [
          _vm._l(_vm.lineProps.relations, function (thisRelation, ri) {
            return [
              thisRelation.isHide === false
                ? _c("g", { key: "l-" + thisRelation.id }, [
                    _c("path", {
                      staticClass: "c-rg-line",
                      class: [
                        thisRelation.styleClass,
                        _vm.graphSetting.checkedLineId == _vm.lineProps.seeks_id
                          ? "c-rg-line-checked"
                          : "",
                      ],
                      style: {
                        "stroke-width":
                          (thisRelation.lineWidth
                            ? thisRelation.lineWidth
                            : _vm.graphSetting.defaultLineWidth) + "px",
                      },
                      attrs: {
                        d: _vm.createLinePath(
                          _vm.lineProps.fromNode,
                          _vm.lineProps.toNode,
                          ri,
                          thisRelation
                        ),
                        stroke: thisRelation.color
                          ? thisRelation.color
                          : _vm.graphSetting.defaultLineColor,
                        "marker-end": _vm.getArrow(
                          thisRelation.isHideArrow,
                          thisRelation.arrow,
                          thisRelation.color
                        ),
                        fill: "none",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onClick($event)
                        },
                      },
                    }),
                    _vm.graphSetting.defaultShowLineLabel &&
                    _vm.graphSetting.canvasZoom > 40
                      ? _c(
                          "g",
                          {
                            attrs: {
                              transform: _vm.getTextTransform(
                                thisRelation,
                                thisRelation.textPositon.x,
                                thisRelation.textPositon.y,
                                thisRelation.textPositon.rotate
                              ),
                            },
                          },
                          [
                            _c(
                              "text",
                              {
                                key: "t-" + thisRelation.id,
                                staticClass: "c-rg-link-text",
                                style: {
                                  fill: thisRelation.fontColor
                                    ? thisRelation.fontColor
                                    : thisRelation.color
                                    ? thisRelation.color
                                    : undefined,
                                },
                                attrs: { x: 0, y: 0 },
                                on: {
                                  click: function ($event) {
                                    return _vm.onClick($event)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(thisRelation.text) + " ")]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }