var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            closable: true,
            title: "合并",
            visible: _vm.visible,
            "mask-closable": false,
            "ok-text": "保存",
            size: "normal",
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _c(
            "a-form-model",
            {
              attrs: {
                "label-col": _vm.formItemLayout.labelCol,
                "wrapper-col": _vm.formItemLayout.wrapperCol,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "选择组织：" } },
                [
                  _c("DepTreeSelect", {
                    attrs: { multiple: "" },
                    model: {
                      value: _vm.selectOrganization,
                      callback: function ($$v) {
                        _vm.selectOrganization = $$v
                      },
                      expression: "selectOrganization",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "合并至" } },
                [
                  _c("DepTreeSelect", {
                    attrs: { multiple: false },
                    model: {
                      value: _vm.merge,
                      callback: function ($$v) {
                        _vm.merge = $$v
                      },
                      expression: "merge",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "explain" }, [
            _c("div", { staticClass: "explain_margin explain-title" }, [
              _vm._v("说明:"),
            ]),
            _c("div", { staticClass: "explain_margin" }, [
              _vm._v(
                "1、合并父级组织时，若子级组织被勾选（无子级组织视为被勾选），合并时，则一并合并到目标组织。原父级、子级组织不保留。"
              ),
            ]),
            _c("div", { staticClass: "explain_margin" }, [
              _vm._v(
                "2、合并父级组织时，若子级组织未勾选，合并时，子级组织相互不合并， 仅移动至目标组织。父级组织更新为目标组织，原父级组织不保留。"
              ),
            ]),
            _c("div", { staticClass: "explain_margin" }, [
              _vm._v(
                "3、合并后，组织下直属员工的“部门”数据相应自动更新为合并后的组织。 合并后组织负责人为目标组织负责人。"
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }